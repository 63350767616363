import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import {makeStyles} from '@material-ui/core/styles'
import Link from '@material-ui/core/Link';
import miniCAUL from '../img/logoMini.png'
import MailIcon from '@material-ui/icons/Mail';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import DraftsIcon from '@material-ui/icons/Drafts';

import MenuBookIcon from '@material-ui/icons/MenuBook';
import BuildIcon from '@material-ui/icons/Build';
import PersonPinIcon from '@material-ui/icons/PersonPin';
import ContactMailIcon from '@material-ui/icons/ContactMail';

const useStyles = makeStyles(theme => ({
      toolbar: theme.mixins.toolbar,
      root: {
        width: '100%',
        maxWidth: 360,
      },
      icon: {
        width: 20,
        height: 20,
        display:"flex",
        justifyContent: 'flex-start',
      }
}))

function ListItemLink(props) {
  return <ListItem button component="a" {...props} />;
}

const Lists = () => {

    const classes = useStyles()

    return (
    <div>
        <div className={classes.toolbar} />
        <Divider />
        <div className={classes.root}>
        <List component="nav" aria-label="main mailbox folders">
        <Link href="/about">
        <ListItem button >
          <ListItemIcon >
            <InboxIcon />
          </ListItemIcon>
          <ListItemText primary="Nosotros" />
        </ListItem>
        </Link>
        <Link href="/experience">
        <ListItem button>
          <ListItemIcon>
            <BuildIcon />
          </ListItemIcon>
          <ListItemText primary="Experiencia" />
        </ListItem>
        </Link>
        <Link href="/clients">
        <ListItem button>
          <ListItemIcon>
            <PersonPinIcon />
          </ListItemIcon>
          <ListItemText primary="Clientes" />
        </ListItem>
        </Link>
        <Link href="/contactus">
        <ListItem button>
          <ListItemIcon>
            <ContactMailIcon />
          </ListItemIcon>
          <ListItemText primary="Contacto" />
        </ListItem>
        </Link>
        </List>
        <Divider />
        </div>
    </div>
    )
}

export default Lists
