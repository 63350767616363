import React from 'react'
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Typography from '../../components/Typography';
import LayoutHome from '../../components/LayoutHome';
import { Helmet } from 'react-helmet';
import Slider from "react-slick";
import Uno from "../../img/uno.jpeg";
import Dos from "../../img/dos.jpeg";
import Tres from "../../img/tres.jpeg";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const styles = (theme) => ({
    root: {
      marginTop: theme.spacing(5),
      marginBottom: theme.spacing(5),
    },
    imageSrc: {
        height: '60vh',
	},
	text:{
		
		fontSize: "15px",
	},
	contImg:{
		marginLeft: "40px",
	}
  });

const ProjectFour = (props) => {
    const { classes } = props;
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        //autoplay: true,
        centerMode: true,
        autoplaySpeed: 1000,
        pauseOnFocus: true,
        initialSlide: 1,
        responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
                infinite: true,
              }
            },
            {
              breakpoint: 768,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true,
              }
            },
            {
              breakpoint: 310,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true,
              }
            }
          ]

      };
    return (
        <LayoutHome >
    	<Helmet>
      		<title>Project Four | CAUL</title>
    	</Helmet>
    <Container className={classes.root} component="section" >
      <Typography 
      	variant="h4"
		    marked="center" 
		    align="center" 
		    component="h2"
		>
        Project Four
    	</Typography>

        <Slider {...settings}>
        	<div className={classes.contImg}>
				
        		<img 
        			src={Uno}
					alt="First slide"
					className={classes.imageSrc}
				/>
				
        		<div className={classes.text}>
        			<h4>
        			  Yellowstone National Park, United States
        			</h4>
				</div>
        	</div>
		
        	<div className={classes.contImg}>
        	  	<img
        	  	  src={Dos}
        	  	  alt="Second slide"
        	  	  className={classes.imageSrc}
        	  	/>
        	  	<div className={classes.text}>
        	  		<h4>
        	  		  Somewhere Beyond, United States
        	  		</h4>
        	  	</div>
        	</div>
		
        	<div className={classes.contImg}>
        	  	<img 
					src={Tres}
					alt="Third slide"
					className={classes.imageSrc}
				/>
        	  	<div className={classes.text}>
        	  	  <h4>                     
        	  	    Yellowstone National Park, United States
        	  	  </h4>
        	  	</div>
        	</div>      
        </Slider>
    	
    </Container>

    </LayoutHome>
    )
}

export default withStyles(styles)(ProjectFour);
