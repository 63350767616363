import React from "react";
// @material-ui/core components
import { withStyles } from '@material-ui/core/styles';
import ButtonBase from '@material-ui/core/ButtonBase';
import Container from '@material-ui/core/Container';
import Typography from '../components/Typography';
import LayoutHome from '../components/LayoutHome';
import Uno from "../img/uno.jpeg";
import Dos from "../img/dos.jpeg";
import Tres from "../img/tres.jpeg";
import Cuatro from "../img/cuatro.jpg";
import Cinco from "../img/cinco.jpg";
import Seis from "../img/seis.jpg";
import Siete from "../img/siete.jpg";
import Ocho from "../img/ocho.jpg";
import Nueve from "../img/nueve.jpg";
import { Helmet } from 'react-helmet';
import Card from "../components/Card/Card";
import Slider from "react-slick";

const styles = (theme) => ({
  root: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
  },
  images: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexWrap: 'wrap',
  },
  imageWrapper: {
    position: 'relative',
    display: 'block',
    padding: 0,
    borderRadius: 0,
    height: '30vh',
    [theme.breakpoints.down('lg')]: {
      height: '33vh',
    },
    [theme.breakpoints.down('lg')]: {
      width: '100% !important',
      height: 80,

    },
    [theme.breakpoints.down('md')]: {
      width: '100% !important',
      height: 80,
    },
    '&:hover': {
      zIndex: 1,
    },
    '&:hover $imageBackdrop': {
      opacity: 0.4,
    },
    '&:hover $imageMarked': {
      opacity: 0,
    },
    '&:hover $imageTitle': {
      border: '4px solid currentColor',
    },
  },
  imageButton: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.common.white,
  },
  imageSrc: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: 'cover',
    backgroundPosition: 'center 20%',
  },
  imageBackdrop: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    background: theme.palette.common.black,
    opacity: 0.15,
    transition: theme.transitions.create('opacity'),
  },
  imageTitle: {
    position: 'relative',
    padding: `${theme.spacing(2)} ${theme.spacing(4)} 14px`,
  },
  imageMarked: {
    height: 3,
    width: 18,
    background: theme.palette.common.white,
    position: 'absolute',
    bottom: -2,
    left: 'calc(50% - 9px)',
    transition: theme.transitions.create('opacity'),
  },
});

function ProductCategories(props) {
	const { classes } = props;
  const images = [
    {
      url: `'${Uno}'`,
      title: 'Clorador',
      width: '33%',
	    href: '/projectOne'
    },
    {
      url: `'${Cinco}'`,
      title: 'Multi-bombas',
      width: '33%',
	    href: '/projectTwo'
    },
    {
      url: `'${Tres}'`,
      title: 'Maquina de vacio',
      width: '33%',
	    href: '/projectThree'
    },/*
    {
      url: `'${Cuatro}'`,
      title: 'Programado',
      width: '30%',
	  href: '/projectFour'
    },

    {
      url: `'${Ocho}'`,
      title: 'Optimizado',
      width: '40%',
	  href: '/projectFive'
    },
    {
      url: `'${Nueve}'`,
      title: 'Garantizado',
      width: '30%',
	  href: '/projectSix'
    },*/
  ];
  
  return (
    <LayoutHome >
    	<Helmet>
      		<title>Experiencia | CAUL</title>
    	</Helmet>
    <Container className={classes.root} component="section" >
      <Typography 
      	variant="h4"
		    marked="center" 
		    align="center" 
		    component="h2"
		>
        Algunos de nuestros trabajos
    	</Typography>
    	<div className={classes.images}>
        	{images.map((image) => (
        	  <ButtonBase
        	    key={image.title}
        	    className={classes.imageWrapper}
        	    style={{
        	      width: image.width,
        	    }}
				    href={image.href}
				
        	  >
				<div
        		  className={classes.imageSrc}
        		  style={{
        		    backgroundImage: `url(${image.url})`,
        		  }}
        		/>
        	    <div className={classes.imageBackdrop} />
        	    <div className={classes.imageButton}>
        	      <Typography
        	        component="h3"
        	        variant="h6"
        	        color="inherit"
        	        className={classes.imageTitle}
        	      >
        	        {image.title}
        	        <div className={classes.imageMarked} />
        	      </Typography>
        	    </div>
				
        	  </ButtonBase>
        	))}
    	</div>
    </Container>

    </LayoutHome>
  );
}

export default withStyles(styles)(ProductCategories);