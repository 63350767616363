import React from 'react'
import MuiAppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Link from '@material-ui/core/Link';
import makeStyles from '@material-ui/core/styles/makeStyles'
import MenuIcon from '@material-ui/icons/Menu'
import IconButton from '@material-ui/core/IconButton'
import CssBaseline from '@material-ui/core/CssBaseline';
import FacebookIcon from '@material-ui/icons/Facebook'
import InstagramIcon from '@material-ui/icons/Instagram';
import miniCAUL from '../img/logoMini.png'


const useStyles = makeStyles(theme => ({
    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.down('xl')]: {
        display: 'none',
      }
    },
    title: {
		fontSize: 20,
		color: '#ffc071'
    },
    appBar: {
      [theme.breakpoints.up('xl')]: {
        width: `calc(100% - ${150}px)`,
        marginLeft: 150,
      }
	},
	toolbar: {
		justifyContent: 'space-between',
	},
	left: {
		flex: 1,
	  },
	right: {
		flex: 1,
		display: 'flex',
		justifyContent: 'flex-end',
	},
	rightLink: {
		fontSize: 16,
		color: theme.palette.common.white,
		marginLeft: theme.spacing(1),
	  },
	  linkSecondary: {
		color: theme.palette.secondary.main,
	},
	icon: {
		width: 40,
		height: 40,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		marginRight: theme.spacing(2),
	}
}))

const Navbar = (props) => {
    const classes = useStyles()

    return (
	<div>
		<CssBaseline />
		<MuiAppBar 
			position="fixed" 
			className={classes.appBar}
		>
			<Toolbar className={classes.toolbar}>
				<IconButton
				  color="inherit"
				  aria-label="open drawer"
				  edge="start"
				  className={classes.menuButton}
				  onClick={props.handleDrawerToggle}
				>
					<MenuIcon />
          		</IconButton>
				<div className={classes.left} />
				<Link
					href="/home"
					underline="none"
          			color="inherit"
				>
				<img
        		src={miniCAUL}
        		alt="Logo Caul"
        		className={classes.icon}
      			/>
				</Link>
          		<Link
          			variant="h3"
          			underline="none"
          			color="#ffc071"
          			className={classes.title}
          			href="/home"
          		>
          		{'CAUL'}
          		</Link>
          		<div className={classes.right}>
				<IconButton 
		        	color="inherit" 
		        	aria-label="instagram"
					href="https://instagram.com/caul_cl?igshid=1n0cm8i11lf0o"
		        >
		        	<InstagramIcon />
		        </IconButton>
				<IconButton 
		          		color="inherit" 
		          		aria-label="Facebook"
						href="https://www.facebook.com/CAUL.spa"
		          	>
		          		<FacebookIcon />
		        </IconButton>
			{/*	<Link
              		color="inherit"
              		variant="h6"
              		underline="none"
              		className={classes.rightLink}
              		href="/premium-themes/onepirate/sign-in/"
            	>
              	{'Sign In'}
            	</Link>
            	<Link
              		variant="h6"
              		underline="none"
              		className={clsx(classes.rightLink, classes.linkSecondary)}
              		href="/premium-themes/onepirate/sign-up/"
            	>
              	{'Sign Up'}
            	</Link>
			*/}
          		</div>
        	</Toolbar>
      </MuiAppBar>
	</div>
    )
}

export default Navbar
