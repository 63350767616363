	
import React from 'react';
import emailjs from 'emailjs-com';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import LayoutHome from './components/LayoutHome'
import Typography from './components/Typography'
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import { Helmet } from 'react-helmet';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/core/Alert';


const useStyles = makeStyles((theme) => ({

    snackbar: {
        width: '100%',
        '& > * + *': {
          marginTop: theme.spacing(2),
        },
      },
    root: {
      '& .MuiTextField-root': {
        margin: theme.spacing(0.2),
      },
      flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
      },
      more: {
        marginTop: theme.spacing(5),
        [theme.breakpoints.up('xs')]: {
            marginTop: theme.spacing(20)
          }
      },
  }));

  
const App = () => {

    const classes = useStyles();
    const [open, setOpen] = React.useState(false); 

    const handleClick = () => {
    setOpen(true);
    };

    const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
    };

	function sendEmail(e) {
		e.preventDefault();
	
		emailjs.sendForm('service_x8rlrt2', 'template_yivgiqd', e.target, 'user_9XOTB6qpLL7KI3jAGTjOp')
		  .then((result) => {
			  console.log(result.text);
		  }, (error) => {
			  console.log(error.text);
		  });
		  e.target.reset()
	  }
   return (
	<LayoutHome >
        <Helmet>
      		<title>Contáctanos | CAUL</title>
    	</Helmet>
		<Typography 
            color="primary" 
            align="center" 
            variant="h2" 
            marked="center"
            className={classes.more}
        >
			Contáctanos
		</Typography>
        <Typography color="primary" align="center" variant="h6" marked="center">
			Envianos tus datos y te contactaremos
		</Typography>
        <br/>
        <Container maxWidth="xs" color="secondary" align="center">
            <form  onSubmit={sendEmail} className={classes.root} noValidate >
                <Grid 
                    container
                    spacing={1}
                    direction="column"
                    alignContent="center"
                >
                <Grid  item xs={10} sm={9} md={9} lg={8} xl={8}>
                <Paper className={classes.paper}>
                    <TextField
                        id="name-input"
                        label="Nombre"
                        placeholder="Nombre del representante"
                        InputProps={{
                            name: "name",
                            form: 1
                        }}
                        variant="outlined"
                    />
                </Paper>
                </Grid>
                <br/>
                <Grid item xs={10} sm={9}  md={9} lg={8} xl={8}>
                <Paper className={classes.paper}>
                    <TextField
                        required
                        id="telephone-input"
                        label="Telefono"
                        placeholder="Telefono de contacto"
                        InputProps={{
                            name: "telephone"
                        }}
                        variant="outlined"
                    />
                </Paper>
                </Grid>
                <br/>
                <Grid item xs={10} sm={9}  md={9} lg={8} xl={8}>
                <Paper className={classes.paper}>
                    <TextField
                        required
                        id="email-input"
                        label="Email"
                        placeholder="Correo electrónico"
                        InputProps={{
                            name: "email"
                        }}
                        variant="outlined"
                    />
                </Paper>
                </Grid>
                <br/>
                <Grid item xs={10} sm={9}  md={9} lg={8} xl={8}>
                <Paper className={classes.paper}>
                    <TextField
                        required
                        id="subject-input"
                        label="Asunto"
                        placeholder="Asunto"
                        InputProps={{
                            name: "subject"
                        }}
                        variant="outlined"
                    />
                </Paper>
                </Grid>
                <br/>
                <Grid item xs={10} sm={9}  md={9} lg={8} xl={8}>
                <Paper className={classes.paper}>
                    <TextField
                        required
                        id="multiline-input"
                        label="Mensaje"
                        multiline
                        rows={4}
                        placeholder="Mensaje"
                        InputProps={{
                            name: "message"
                        }}
                        variant="outlined"
                    />
                </Paper>
                </Grid>
                </Grid>
                <Button
                    size="large"
                    variant="contained"
                    color="secondary"
                    endIcon={<Icon>send</Icon>}
                    type="submit"
                    onClick={handleClick}
                >
                 Enviar 
                </Button>
                
                </form>
                <Snackbar open={open} autoHideDuration={2000} onClose={handleClose}>
                    <Alert 
                        onClose={handleClose} 
                        severity="success"
                        elevation={6} 
                        variant="filled"
                    >
                        Tus datos han sido enviados satisfactoriamente
                    </Alert>
                </Snackbar>
            </Container>
	</LayoutHome>
   );
 }
 export default App;
