import React from 'react'
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Typography from '../components/Typography';
import LayoutHome from '../components/LayoutHome';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import { Helmet } from 'react-helmet';
import Ariztia from "../img/Ariztia.png";

const styles = (theme) => ({
    root: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(10),
    },
    imageSrc: {
        height: '60vh',
	},
	text:{
		
		fontSize: "15px",
	},
	contImg:{
		marginLeft: "40px",
	},
    card: {
        maxWidth: 450,
      },
      media: {
        height: 250,
      },
  });

const Clients = (props) => {
    const { classes } = props;
    return (
    <LayoutHome >
    	<Helmet>
      		<title>Clientes | CAUL</title>
    	</Helmet>
        <Container className={classes.root} component="section" >
        <Typography 
      	variant="h4"
		    marked="center" 
		    align="center" 
		    component="h2"
		>
        Clientes
    	</Typography>
        <div align="center" >

        
        <Card className={classes.card} align="center">
        <CardActionArea>
            <CardMedia
              className={classes.media}
              image={Ariztia}
              title="Ariztía"
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="h2">
                Ariztía
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p">
               - Projecto drake 1300: Tablero de control y actualizacion de software
               <br />
               - Projecto Maquina de vacio: Tablero de control y programación
              </Typography>
            </CardContent>
        </CardActionArea>
        </Card>
        </div>


        </Container>

    </LayoutHome>
    )
}

export default withStyles(styles)(Clients);
