import React from 'react';
import {ThemeProvider} from '@material-ui/core/styles'
import theme from "./temaConfig";
import Container from "./Container";



export default function App() {

  return (
    <ThemeProvider theme={theme}>
      <Container />
      
    </ThemeProvider>  
  );
}
