import React from 'react'
import {makeStyles} from '@material-ui/core/styles'
import Navbar from './views/Navbar'
import Dravver from './views/Dravver'
import AppFooter from './views/Footer'
import AppRoutes from './components/AppRoutes.js'


const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',    
    },
}))

const Container = () => {

    const classes = useStyles()
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const handleDrawerToggle = () => {
      setMobileOpen(!mobileOpen);
    }
    return (
        <React.Fragment>
        <div className={classes.root}>

            <Navbar 
                handleDrawerToggle={handleDrawerToggle} 
            />
            <Dravver 
                handleDrawerToggle={handleDrawerToggle}
                mobileOpen={mobileOpen}
            /> 
        </div>
        <AppRoutes />
        <AppFooter />
        </React.Fragment>
    )
}

export default Container
