import React from 'react'
import {makeStyles} from '@material-ui/core/styles'
import Drawer from '@material-ui/core/Drawer'
import {useTheme} from '@material-ui/core'
import Hidden from '@material-ui/core/Hidden';
import Lists from '../components/List';

const useStyles = makeStyles(theme => ({
    drawer: {
      [theme.breakpoints.up('xl')]: {
        width: 200,
        flexShrink: 0,
      },
    },
      drawerPaper: {
        width: 200,
      },
      toolbar: theme.mixins.toolbar,
}))

const Dravver = (props) => {
    const classes = useStyles()
    const theme = useTheme()
 
   
    return (
      <nav className={classes.drawer} aria-label="mailbox folders">
      <Hidden xlUp >
        <Drawer
          variant="temporary"
          anchor={theme.direction === 'rtl' ? 'right' : 'left'}
          open={props.mobileOpen} 
          onClose={props.handleDrawerToggle}
          classes={{
            paper: classes.drawerPaper,
          }}
          
        >
          <Lists />
        </Drawer>
      </Hidden>
      <Hidden xlDown >
        <Drawer
          classes={{
            paper: classes.drawerPaper,
          }}
          variant="permanent"
          open
        >
          <Lists />
        </Drawer>
      </Hidden>
    </nav>
    )
}

export default Dravver
