import * as React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from "@material-ui/core/styles";
import LayoutHome from '../components/LayoutHome';
import { Helmet } from 'react-helmet';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import portadaNos from '../img/ulloa.jpg';
import logoPortada from '../img/LogoCortado.png';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople';

const styles = (theme) => ({
	section: {
		padding: "30px 0"
	  },
	  textCenter: {
		textAlign: "center",
		fontSize: "30px",
		[theme.breakpoints.down('xs')]: {
			fontSize: "30px",
        },
		root: {
			maxWidth: 300,
		  },
		container:{
			
		},
		layout:{
			height: '80vh',
		}

	  },
  });

function TextWho(props) {
	const { classes } = props;

  return (
    <LayoutHome className={classes.layout}>
		<Helmet>
      		<title>Nosotros | CAUL</title>
    	</Helmet>
		<div className={classes.section}>
      		<div className={classes.container}>
        		<div id="nav-tabs">
        			<Typography 
						color="primary" 
						align="center" 
						variant="h2" 
					>
						Nosotros
					</Typography>
        				<Grid container  justify="center" spacing={2}>
							<Grid item lg={6} md={6} sm={6} xs={12}>
								<Card className={classes.root}>
      								<CardActionArea>
        								<CardMedia
        								  component="img"
        								  alt="logoPortada"
        								  height="450"
        								  src={logoPortada}
        								  title="logoPortada"
        								/>
        								<CardContent>
        									<Typography gutterBottom variant="h5" component="h2">
        									    CAUL | Electricidad y automatización
        									</Typography>
        									<Typography variant="body1" color="textSecondary" component="p">
												Somos una empresa joven y dinámica, emergente
												en chile. Con un equipo de trabajo calificado,
												responsable y comprometido con un servicio personalizado 
												y de máxima calidad, en constante desarrollo personal, 
												profesional y tecnológico.
												Además de contar con un excelente servicio postventa acompañando 
												al cliente en todo el proceso con asesorías personalizadas y con precios 
												competitivos en el mercado.
        									</Typography>
        								</CardContent>
      								</CardActionArea>
      								<CardActions>
										<Button
    				  						color="secondary"
    				  						variant="contained"
    				  						size="large"
    				  						className={classes.button}
    				  						endIcon={<EmojiPeopleIcon />}
    				  						component="a"
    				  						href="/contactus"
    									>
    				  					Contáctanos
    									</Button>
        								
      								</CardActions>
    							</Card>
							</Grid>

							<Grid item lg={6} md={6} sm={6} xs={12}>
								<Card className={classes.root}>
      								<CardActionArea>
        								<CardMedia
        								  component="img"
        								  alt="portadaNos"
        								  height="300"
        								  src={portadaNos}
        								  title="portadaNos"
        								/>
        								<CardContent>
        								  <Typography gutterBottom variant="h5" component="h2">
        								    Nuestra visión
        								  </Typography>
        								  <Typography variant="body1" color="textSecondary" component="p">
										  	CAUL se visualiza como una empresa referente en el área de Automatización y proyectos eléctricos 
											abarcando áreas de alto impacto en chile, comprometidos con el medio ambiente y el uso eficiente 
											de la energía.
											Nuestro compromiso como empresa es brindar un servicio completo que le garantice seguridad y confianza.
											<br />
											☆ Fabricación y diseños de tableros eléctrico (ingeniería de detalle)
											<br />
											☆ Programación de controladores, microcontroladores, variadores de frecuencia y partidores suaves
											<br />
											☆ Configuración de HMI de procesos
											<br />
											☆ Integración de sistemas SCADAS
											<br />
											☆ Diseño y certificación de planos eléctricos
											<br />
											☆ Servicios de puesta en marcha y mantenciones.
        								  </Typography>
        								</CardContent>
      								</CardActionArea>
    							</Card>
							</Grid>
						</Grid>	
				</div>
      		</div>
    	</div>
	</LayoutHome>
  );
}

TextWho.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(TextWho);
