import React from 'react'
import { BrowserRouter, Route, Switch } from "react-router-dom";
import NotFound from '../views/NotFound';
import TextWho from '../views/TextWho';
import Who from '../views/Who'
import App from '../Email'
import Carousel from '../views/Carousel'
import ProjectOne from '../views/Projects/ProjectOne';
import ProjectTwo from '../views/Projects/ProjectTwo';
import ProjectThree from '../views/Projects/ProjectThree';
import ProjectFour from '../views/Projects/ProjectFour';
import ProjectFive from '../views/Projects/ProjectFive';
import ProjectSix from '../views/Projects/ProjectSix';
import Clients from '../views/Clients';

const AppRoutes = () => {
    return (
    <BrowserRouter>
        <Switch>
            <Route path="/home" exact>
                <Who />
            </Route>
            <Route path="/about" exact>
                <TextWho />
            </Route>
            <Route path="/clients" exact>
                <Clients />
            </Route>
            <Route path="/contactus" exact>
                <App />
            </Route>
            <Route path="/experience" exact>
                <Carousel />
            </Route>
            <Route path="/projectOne" exact>
                <ProjectOne />
            </Route>
            <Route path="/projectTwo" exact>
                <ProjectTwo />
            </Route> 
            <Route path="/projectThree" exact>
                <ProjectThree />
            </Route> 
            <Route path="/projectFour" exact>
                <ProjectFour />
            </Route> 
            <Route path="/projectFive" exact>
                <ProjectFive />
            </Route> 
            <Route path="/projectSix" exact>
                <ProjectSix />
            </Route> 
            <Route path="/" exact>
                <Who />
            </Route>
            <Route path="/">
                <NotFound />
            </Route>
            
        </Switch>
    </BrowserRouter>
        
    )
}

export default AppRoutes
