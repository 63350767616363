import { Helmet } from 'react-helmet';
import {
  Box,
  Container,
  Typography
} from '@material-ui/core';
import backgroundImage from '../img/homebackgrond.jpg'
import LayoutHome from '../components/LayoutHome';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
    background: {
      backgroundImage: `url(${backgroundImage})`,
      backgroundColor: '#7fc7d9', // Average color of the background image.
      backgroundPosition: 'center',
    },
    h5: {
      marginBottom: theme.spacing(4),
      marginTop: theme.spacing(4),
      [theme.breakpoints.up('sm')]: {
        marginTop: theme.spacing(10),
      },
    },
});


function NotFound(props) {

    const { classes } = props;
    return(
    <LayoutHome backgroundClassName={classes.background}>
    <Helmet>
      <title>404 | Pagina no encontrada</title>
    </Helmet>
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      }}
    >
      <Container maxWidth="md">
        <Typography
          align="center"
          color="textPrimary"
          variant="h2"
        >
          404: La página que estás buscando no está aquí.
        </Typography>
        <Typography
          align="center"
          color="textPrimary"
          variant="subtitle2"
        >
          O intentaste alguna ruta con sombra o viniste aquí por error.
           Sea lo que sea, intente usar la navegación
        </Typography>
      </Container>
    </Box>
    </LayoutHome>
    );
}

NotFound.propTypes = {
    classes: PropTypes.object.isRequired,
  };
  
  export default withStyles(styles)(NotFound);