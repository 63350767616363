import * as React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '../components/Button.jsx';
import Typography from '../components/Typography.js';
import LayoutHome from '../components/LayoutHome';
import backgroundImage from '../img/homebackgrond.jpg';
import CAUL from '../img/logoCaulHome.png';
import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople';
import SendIcon from '@material-ui/icons/Send';
import Container from '@material-ui/core/Container';
import Zoom from '@material-ui/core/Zoom';

const styles = (theme) => ({
  background: {
    backgroundImage: `url(${backgroundImage})`,
    backgroundColor: '#7fc7d9', // Average color of the background image.
    backgroundPosition: 'center',
  },
  button: {
    minWidth: 200,
  },
  h5: {
    marginBottom: theme.spacing(4),
    marginTop: theme.spacing(4),
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(10),
    },
  },
  more: {
    marginTop: theme.spacing(2),
  },
  icon: {
    width: 240,
    height: 240,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: theme.spacing(1),
  },
});

function ProductHero(props) {
  const { classes } = props;

  return (
    <LayoutHome backgroundClassName={classes.background}>
    	
      {/* Increase the network loading priority of the background image. */}
      <img
        style={{ display: 'none' }}
        src={backgroundImage}
        alt="increase priority"
      />
      <Container fixed maxWidth="xl" align="center">
	    	<Zoom 
			  	in={true} 
			  	style={{ transitionDelay: '500ms' }}
			  >
			<div>
        		<Typography color="inherit" align="center" variant="h2" marked="center">
        		Quienes somos ?
        		</Typography>
			</div>
	    	</Zoom>
			<Zoom 
			  	in={true} 
			  	style={{ transitionDelay: '1s' }}
				timeout={500}
			  >
				<div>
    				<img
    				  src={CAUL}
    				  alt="Logo Caul"
    				  className={classes.icon}
    				/>
    				<Button
    				  color="secondary"
    				  variant="contained"
    				  size="large"
    				  className={classes.button}
    				  endIcon={<EmojiPeopleIcon />}
    				  component="a"
    				  href="/about"
    				>
    				  CONóCENOS
    				</Button>
            <br /><br />
            <Button
    				  color="secondary"
    				  variant="contained"
    				  size="large"
    				  className={classes.button}
    				  endIcon={<SendIcon />}
    				  component="a"
    				  href="/contactus"
    				>
    				  Contáctanos
    				</Button>
    				<Typography variant="body2" color="inherit" className={classes.more}>
    				  Descubre una nueva experiencia
    				</Typography>
				</div>
			</Zoom>
      </Container>
      
    </LayoutHome>
  );
}

ProductHero.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ProductHero);
